import React from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";
import { format } from '@cloudinary/url-gen/actions/delivery';
// import logo from '../../assets/images/logos/1.svg'

function SectionBanner({ title, desc, link, imageUrl, clientlogo, noHover, titlePosition, view }) {

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'dci1aiukm'
    }
  });

  const myImage = cld.image(imageUrl).quality('auto').delivery(format('webp'));

  return (
    <div className={`bs-section-banner ${clientlogo ? 'bs-section-banner--typ-full-height' : ''} ${noHover ? 'bs-section-banner--no-hover-interaction' : ''} ${view === 'sm' ? 'bs-section-banner--info-small' : ''}`}>
      {titlePosition === 'top' ? (
        <div className='bs-section-banner__title-wrap'>
          <h3 className='bs-section__title'>{title}</h3>
        </div>
      ) : null}
      {/* {imageUrl ? ( */}
        <div className='bs-section-banner__media-wrap'>
          <AdvancedImage cldImg={myImage} loading="lazy" className='bs-section-banner__image' />
        </div>
      {/* ) : null} */}
      <div className='bs-section-banner__info-wrap'>
        {titlePosition === 'top' ? null : <h3 className='bs-section__title'>{title}</h3>}
        <div className='bs-section-banner__desc-wrap'>
          <p className='bs-section__desc'>{desc}</p>
        </div>
        {clientlogo &&
          <div className='lyt-logo'>
            {clientlogo.map((logo, index) => {
              console.log(logo, 'logo');
              return(
                <div className='lyt-logo__img-wrap' key={index}>
                  <img src={logo.src} alt={logo.name} />
                </div>
              )
            })}
          </div>
        }
        {/* {link && <a href={link} className='bs-btn bs-btn__btn-solid'>learn more</a>} */}
      </div>
    </div>
  )
}

export default SectionBanner